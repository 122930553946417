import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs, { stringify } from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const useinventoryStore = defineStore('inventorystore', {
    state: () => {
        return {
            showStockPage : true,
            inventoryDashboardDetails: [],
            inventoryActivityDetails: [],
            inventoryItemTrackDetails: [],
            inventoryStockDetails: [],
            inventoryStockDetailsTable: [],
            inventoryItemCategoryDetails: [],
            itemSampleFilePath: '',
            vendorManagementDetails: [],
            vendorManagementTableDetails: [],
            vendorSampleFilePath: '',
            locationManagementDetails: [],
            locationSampleFilePath: '',
            locationManagementTableDetails: [],
            inventoryApprovalDetails: [],
            inventoryApprovePending : [],
            inventoryApprovalTableDetails: [],
            inventoryConfigurationDetails: [],
            inventoryStockOverviewDetails: [],
            inventoryStockOverviewTableDetails: [],
            add_item: [],
            add_vendor: [],
            requests: [],
            selectedStock : {},
            stockItemSampleFilePath: '',
            transferredItemsPercent: "",
            deleteStockItemPercent: "",
            inventoryStockAddPercent:"",
            stockQuality:"",
        }
    },
    getters: {
        inventoryDashboardVisible: (state) => state.showStockPage,
        InventoryDashboardVisibleGetters() {
            return this.inventoryDashboardVisible;
        },
        stockQualityState: (state) => state.stockQuality,
        stockQualityStateGetters() {
            return this.stockQualityState;
        },
        inventoryDashboardDetailsState: (state) => state.inventoryDashboardDetails,
        getInventoryDashboardGetters() {
            return this.inventoryDashboardDetailsState;
        },
        inventoryActivityDetailsState: (state) => state.inventoryActivityDetails,
        getInventoryActivityTableGetters() {
            return this.inventoryActivityDetailsState;
        },
        getRequests: (state) => state.requests,
        inventoryItemTrackDetailsState: (state) => state.inventoryItemTrackDetails,
        InventoryItemTrackDetailsGetter() {
            return this.inventoryItemTrackDetailsState;
        },
        inventorySelectedStockState: (state) => state.selectedStock,
        InventorySelectedStockGetter() {
            return this.inventorySelectedStockState;
        },
        inventoryStockDetailsState: (state) => state.inventoryStockDetails,
        InventoryStockDetailsGetter() {
            return this.inventoryStockDetailsState;
        },
        inventoryStockDetailsTableState: (state) => state.inventoryStockDetailsTable,
        InventoryStockDetailTableGetter() {
            return this.inventoryStockDetailsTable;
        },
        inventoryItemCategoryDetailsState: (state) => state.inventoryItemCategoryDetails,
        InventoryItemCategoryGetters() {
            return this.inventoryItemCategoryDetailsState;
        },
        itemSampleFilePathState: (state) => state.itemSampleFilePath,
        getsampleInventoryItemSampleFilePath() {
            return this.itemSampleFilePathState;
        },
        vendorSampleFilePathState: (state) => state.vendorSampleFilePath,
        getsampleVendorSampleFilePath() {
            return this.vendorSampleFilePathState;
        },
        vendorManagementDetailsState: (state) => state.vendorManagementDetails,
        vendorManagementListGetters() {
            return this.vendorManagementDetailsState;
        },
        vendorManagementTableDetailsState: (state) => state.vendorManagementTableDetails,
        vendorManagementTableGetter() {
            return this.vendorManagementTableDetailsState;
        },
        locationManagementDetailsState: (state) => state.locationManagementDetails,
        locationManagementListGetters() {
            return this.locationManagementDetailsState;
        },
        locationSampleFilePathState: (state) => state.locationSampleFilePath,
        getsampleLocationSampleFilePath() {
            return this.locationSampleFilePathState;
        },
        locationManagementTableDetailsState: (state) => state.locationManagementTableDetails,
        locationManagementTableGetter() {
            return this.locationManagementTableDetailsState;
        },
        inventoryApprovalDetailsState: (state) => state.inventoryApprovalDetails,
        inventoryApprovalListGetters() {
            return this.inventoryApprovalDetailsState;
        },
        inventoryApprovalPendingState: (state) => state.inventoryApprovePending,
        inventoryApprovalPendingGetters() {
            return this.inventoryApprovalPendingState;
        },
        inventoryApprovalTableDetailsState: (state) => state.inventoryApprovalTableDetails,
        inventoryApprovalTableGetter() {
            return this.inventoryApprovalTableDetailsState;
        },
        inventoryConfigurationDetailsState: (state) => state.inventoryConfigurationDetails,
        InventoryConfigurationGetters() {
            return this.inventoryConfigurationDetailsState;
        },
        inventoryStockOverviewDetailsState: (state) => state.inventoryStockOverviewDetails,
        InventoryStockOverviewGetter() {
            return this.inventoryStockOverviewDetailsState;
        },
        inventoryStockOverviewTableDetailsState: (state) => state.inventoryStockOverviewTableDetails,
        InventoryStockOverviewTableGetter() {
            return this.inventoryStockOverviewTableDetailsState;
        },
        add_itemState: (state) => state.add_item,
        addItemGetterstate() {
            return this.add_itemState
        },
        add_vendorState: (state) => state.add_vendor,
        addVendorGetterstate() {
            return this.add_vendorState
        },
        stockItemSampleFilePathState: (state) => state.stockItemSampleFilePath,
        getsampleStockItemSampleFilePath() {
            return this.stockItemSampleFilePathState;
        },
        transferredItemsPercentState: (state) => state.transferredItemsPercent,
        socketTransferredItemsUpdate() {
            return this.transferredItemsPercentState;
        },
        deleteStockItemPercentState: (state) => state.deleteStockItemPercent,
        socketStockDeletedItems() {
            return this.deleteStockItemPercentState;
        },
        inventoryStockAddPercentState: (state) => state.inventoryStockAddPercent,
        socketInventoryAdd() {
            return this.inventoryStockAddPercentState;
        },
    },
    actions: {
      socket_inventoryStockAddPercentage(data) {
        console.log(data);
        this.inventoryStockAddPercent = data;
      },
      socket_transferredItemsPercentage(data) {
        console.log(data);
        this.transferredItemsPercent = data;
      },
      socket_deleteStockDetailPercentage(data) {
        console.log(data);
        this.deleteStockItemPercent = data;
      },
      addRequest(request) {
        this.requests.push(request);
        this.clearInventoryDashboardDetailsState();
      },
      
      addSelectedStock(request,addSelectedStock) {
        if (!this.selectedStock[addSelectedStock]) {
          this.selectedStock[addSelectedStock] = [];
        }
        const categoryArr = this.selectedStock[addSelectedStock]
        const requestArr = [...categoryArr,...request]
        const uniqueArr = [...new Set(requestArr)]
        this.selectedStock[addSelectedStock] = uniqueArr;
      },
      updateRequest(updatedRequest) {
        const index = this.requests.findIndex(req => req.id === updatedRequest.id);
        if (index !== -1) {
          this.requests[index] = updatedRequest;
        }
        this.clearInventoryDashboardDetailsState();
      },
      clearRequests() {
        this.requests = [];
      },
      removeRequestItem(itemId) {
        
        
        this.requests = this.requests.filter(item => String(item.id) !== String(itemId.value));
      },
      removeAssignedItem(stockId){
        console.log("Deleting stockId:", stockId);
      console.log("Before deletion:", JSON.stringify(this.selectedStock));
        for (const requestId in this.selectedStock) {
          if (String(this.selectedStock[requestId]).includes(String(stockId.value))) {
              // Remove stockId from the array

              this.selectedStock[requestId] = this.selectedStock[requestId].filter(id => String(id) !== String(stockId.value));

              // If the array is empty after removal, delete the requestId
              if (this.selectedStock[requestId].length === 0) {
                  delete this.selectedStock[requestId];
              }
              break; // Stop after finding and deleting the stockId
          }
      }
      },
        clearStockDetailState() {
            this.inventoryApprovalTableDetails = [];
            // this.inventoryDashboardDetails = [];
            this.inventoryActivityDetails = [];
            this.inventoryItemTrackDetails = [];
            this.inventoryStockDetails = [];
            this.inventoryStockDetailsTable = [];
            this.inventoryItemCategoryDetails = [];
            this.itemSampleFilePath = '';
            this.vendorManagementDetails = [];
            this.vendorManagementTableDetails = [];
            this.vendorSampleFilePath = '';
            this.locationManagementDetails = [];
            this.locationSampleFilePath = '';
            this.locationManagementTableDetails = [];
            // this.inventoryApprovalDetails = [];
            // this.inventoryApprovalTableDetails = [];
            this.inventoryConfigurationDetails = [];
            this.inventoryStockOverviewDetails = [];
            this.inventoryStockOverviewTableDetails = [];
            this.add_item = [];
            this.add_vendor = [];
            this.stockItemSampleFilePath = '';
        },
        clearSelectedStockState() {
          this.selectedStock = {};
        },
        clearInventoryDashboardDetailsState() {
          this.inventoryDashboardDetails = [];
        },
        clearapproveDetails() {
          this.inventoryApprovalTableDetails = [];
        },
        clearapprovalPending() {
          this.inventoryApprovePending = [];
        },
        fetchInventoryDashboardList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/inventory_dashboard/' + postData.timeRange + '/' + postData.account + '/true')
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = response.data.message;
                  this.inventoryDashboardDetails = new Array();
                  if (parseData != null) {
                    tData['inventoryDashboardDetails'] = parseData;
                    this.inventoryDashboardDetails = tData;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        fetchInventoryNewDashboardList(postData) {
          Nprogress.start()
          return new Promise((resolve, reject) => {        
            axios.get('/inventory/inventory_dashboard/')
              .then(response => {
                Nprogress.done()
                var tData = {};
                var parseData = response.data.message;
                this.inventoryDashboardDetails = new Array();
                if (parseData != null) {
                  tData['inventoryDashboardDetails'] = parseData;
                  this.inventoryDashboardDetails = tData;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
      },
        getInventoryGraphData(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/get_inventory_graph', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  if (res.data.status == "redirect") {
                    router.push(res.data.message);
                  } else {
                    resolve(res.data);
                  }
                })
                .catch(error => console.log(error))
            });
        },
        fetchInventoryActivityList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/inventory_log_data', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.inventoryActivityDetails = new Array();
                  if (parseData != null) {
                    tData['inventoryActivityDetails'] = parseData;
                    this.inventoryActivityDetails = tData;
                  }
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        fetchInventoryDashboardTrackItemList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/track_item', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        fetchInventoryItemTrackDetail(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/inventory_item_track_detail', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        fetchInventoryItemTrackList(postData) {
          JsLoadingOverlay.show({
            'spinnerIcon': 'ball-clip-rotate',
            "overlayOpacity": "0.6",
            "spinnerSize": "2x",
            "lockScroll": true,
        });
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/inventory_item_track/' + postData.accountId + '/' + postData.trackSerial + '/true')
                .then(response => {
                  JsLoadingOverlay.hide();
                  var tData = {};
                  var parseData = response.data.message;
                  this.inventoryItemTrackDetails = new Array();
                  if (parseData != null) {
                    tData['inventoryItemTrackDetails'] = parseData;
                    this.inventoryItemTrackDetails = tData;
                  }                  
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        submitTransferItemsAll(postData) {
              return new Promise((resolve, reject) => {
               JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                  
                  axios.post('/inventory/transfer_items_all', postData, {
                      headers: { 'Content-Type': 'multipart/form-data' }
                  })
                      .then(response => {
                          JsLoadingOverlay.hide();
                          if (response.data.status != 'success') {
                              reject(response.data.message);
                              return;
                          }
                          this.clearInventoryDashboardDetailsState();
                          resolve(response.data);
        
                      })
                      .catch(error => console.log(error));
              });
          },  
          fetchInventoryStockDetailList() {
            Nprogress.start()
            return new Promise((resolve, reject) => {
        
              axios.get('/inventory/goods_in_use/true')
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = response.data.message;
                  this.inventoryStockDetails = new Array();
                  if (parseData != null) {
                    tData['inventoryStockDetails'] = parseData;
                    this.inventoryStockDetails = tData;
                  }                  
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryStockDetailTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/track_item_details_data', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.inventoryStockDetailsTable = new Array();
                  if (parseData != null) {
                    tData['inventoryStockList'] = parseData;
                    this.inventoryStockDetailsTable = tData;
                  }                  
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          returnToStockModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/item_return_to_stock', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error))
            });
          },
          changeItemStatusModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/change_item_status', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.itemTranId;
                    if (Object.keys(this.inventoryStockDetailsTable).length != 0) {
                      var index = this.inventoryStockDetailsTable["inventoryStockList"].findIndex(stock => stock.inventory_item_transfers.id == id);
                      this.inventoryStockDetailsTable["inventoryStockList"][index].inventory_item_transfers.status = "received";
                    }
                    this.clearInventoryDashboardDetailsState();
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error))
            });
          },
          changeItemQualityModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/change_goods_quality', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.itemTranId;
                    var quality = postData.itemQuality;
                    if (Object.keys(this.inventoryStockDetailsTable).length != 0) {
                      var index = this.inventoryStockDetailsTable["inventoryStockList"].findIndex(stock => stock.inventory_item_transfers.id == id);
                      this.inventoryStockDetailsTable["inventoryStockList"][index].inventory_item_transfers.quality = quality;
                      this.stockQuality = quality;
                    }                    
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error))
            });
          },
          changeItemMACModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/change_goods_mac', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.itemTranId;
                    var mac = postData.trackMacMac;
                    if (Object.keys(this.inventoryStockDetailsTable).length != 0) {
                      var index = this.inventoryStockDetailsTable["inventoryStockList"].findIndex(stock => stock.inventory_item_transfers.id == id);
                      this.inventoryStockDetailsTable["inventoryStockList"][index].inventory_item_transfers.item_mac = mac;
                    }                    
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error))
            });
          },
        
          getSelectedInventoryItemDetails(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_selected_items_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.message != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error))
            });
          },
        
          getInventoryItemDetails(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_item_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.message != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error))
            });
          },
          fetchInventoryItemCategoryDetails(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.post('/inventory/inventory_items/true',qs.stringify(postData,{
                parseArrays:false
              }))
                .then(response => {
                  Nprogress.done()
                  if (response.data.status == "redirect") {
                    router.push(response.data.message);
                  } else {
                    var tData = {};
                    var parseData = response.data.message;
                    this.inventoryItemCategoryDetails = new Array();
                    this.itemSampleFilePath = parseData.samplePath;
                    if (parseData != null) {
                      tData['inventoryItemCategoryDetails'] = parseData;
                      this.inventoryItemCategoryDetails = tData;
                    }                    
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          createItemCategoryModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/create_item_category', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var data = {
                      id: postData.inputItemCategoryId,
                      name: postData.itemCategoryName,
                    }
                    if (postData.addEditType == "edit") {
                      var id = data.id;
                      var name = data.name;
                      if (Object.keys(this.inventoryItemCategoryDetails).length != 0) {
                        var index = this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].itemCategories.findIndex(item => item.InventoryItemCategory.id == id);
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].itemCategories[index].InventoryItemCategory.name = name;
                      }                      
                    }
                    resolve(response.data);
                  }
        
                })
                .catch(error => console.log(error))
            });
          },
          deleteItemCategoryModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_item_category', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.deleteItemId;
                    if (Object.keys(this.inventoryItemCategoryDetails).length != 0) {
                      var index = this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].itemCategories.findIndex(item => item.InventoryItemCategory.id == id);
                      this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].itemCategories.splice(index, 1);
                    }                    
                    resolve(response.data);
                  }
        
                })
                .catch(error => console.log(error))
            });
          },
          createItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/create_item', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var data = {
                      id: postData.itemId,
                      itemName: postData.itemName,
                      itemBrand: postData.itemBrand,
                      itemCode: postData.itemCode,
                      unitOfMeasure: postData.unitOfMeasure,
                      warranty: postData.warranty,
                      itemType: postData.itemType,
                      itemPrice: postData.itemPrice,
                      minStock: postData.minStock,
                    }
                    if (postData.addEditType == "edit") {
                      var id = data.id;
                      if (Object.keys(this.inventoryItemCategoryDetails).length != 0) {
                        var index = this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items.findIndex(item => item.InventoryItem.id == id);
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.name = data.itemName;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.brand = data.itemBrand;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.code = data.itemCode;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.min_stock = data.minStock;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.price = data.itemPrice;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.type = data.itemType;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.unit_of_measure = data.unitOfMeasure;
                        this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items[index].InventoryItem.warranty = data.warranty;
                      }                      
                    }
                    this.addItem(response.data.data);                   
                    resolve(response.data);
                  }
        
                })
                .catch(error => console.log(error))
            });
          },
          deleteItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_item', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.itemId;
                    if (Object.keys(this.inventoryItemCategoryDetails).length != 0) {
                      var index = this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items.findIndex(item => item.InventoryItem.id == id);
                      this.inventoryItemCategoryDetails["inventoryItemCategoryDetails"].items.splice(index, 1);
                    }                    
                    resolve(response.data);
                  }        
                })
                .catch(error => console.log(error))
            });
          },
          fetchInventoryRequestItems() {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.post('/inventory/inventory_request_items')
                .then(response => {
                  Nprogress.done()
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          getstockApproveDetails(postData){
            Nprogress.start()
            return new Promise((resolve,reject)=>{
              axios.post('/inventory/inventory_stock_approval',qs.stringify(postData,{
                parseArrays:false
              }))
                .then(response=>{
                  Nprogress.done()
                  resolve(response.data)
                  console.log(response.data.message)
                })
                .catch(error => console.log(error));
            });
          },
          getInventoryCategories(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_inventory_categories', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          submitRequestItemForm(postData) {
            console.log("POST:",postData)
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/raise_request_inventory', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  this.clearInventoryDashboardDetailsState();
                  resolve(response.data);
                  console.log("RESPONSE:",response.data)
                })
                .catch(error => console.log(error));
            });
          },
          importItemModal(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_item_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importItemCopy(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_item_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchVendorManagementDetail() {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/vendor_management/true')
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = response.data.message;
                  this.vendorSampleFilePath = parseData.samplePath;
                  this.vendorManagementDetails = new Array();
                  if (parseData != null) {
                    tData['vendorDetails'] = parseData;
                    this.vendorManagementDetails = tData;
                  }                  
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchVendorManagementTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/vendor_management_data', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  if (res.data.status == "redirect") {
                    router.push(res.data.message);
                  } else {
                    var tData = {};
                    var parseData = JSON.parse(res.data.message);
                    this.vendorManagementTableDetails = new Array();
                    if (parseData != null) {
                      tData['vendorLists'] = parseData;
                      this.vendorManagementTableDetails = tData;
                    }                    
                    resolve(res.data);
                  }
                })
                .catch(error => console.log(error))
            });
          },
          addVendorSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/vendor_management', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    if (postData.type == "edit") {
                      var id = postData.vendorId;
                      if (Object.keys(this.vendorManagementTableDetails).length != 0) {
                        var index = this.vendorManagementTableDetails["vendorLists"].findIndex(vendor => vendor.VendorManagement.id == id);
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.name = postData.vendorName;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.mobile = postData.phone;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.landline = postData.landline;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.email = postData.email;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.currency = postData.currency;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.country = postData.country;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.contact_person = postData.contactPerson;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.company_name = postData.companyName;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.code = postData.vendorCode;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.address_state = postData.address_state;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.address_pin = postData.address_pin;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.address_line2 = postData.address_line2;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.address_line1 = postData.address_line1;
                        this.vendorManagementTableDetails["vendorLists"][index].VendorManagement.address_city = postData.address_city;
                      }                      
                    }
                    this.addVendor(response.data.data);                    
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          deleteVendorModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_vendor', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.vendorId;
                    if (Object.keys(this.vendorManagementTableDetails).length != 0) {
                      var index = this.vendorManagementTableDetails["vendorLists"].findIndex(vendor => vendor.VendorManagement.id == id);
                      this.vendorManagementTableDetails["vendorLists"].splice(index, 1);
                    }                    
                    resolve(response.data);
                  }        
                })
                .catch(error => console.log(error))
            });
          },
          importVendorModal(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_vendor_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importVendorCopy(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_vendor_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchlocationManagementDetail() {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/manage_locations/true')
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = response.data.message;
                  this.locationSampleFilePath = parseData.samplePath;
                  this.locationManagementDetails = new Array();
                  if (parseData != null) {
                    tData['locationDetails'] = parseData;
                    this.locationManagementDetails = tData;
                  }                  
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchlocationManagementTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/manage_locations_data', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  if (res.data.status == "redirect") {
                    router.push(res.data.message);
                  } else {
                    var tData = {};
                    var parseData = JSON.parse(res.data.message);
                    this.locationManagementTableDetails = new Array();
                    if (parseData != null) {
                      tData['locationLists'] = parseData;
                      this.locationManagementTableDetails = tData;
                    }                    
                  }
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          fetchlocationDetailsList(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_location_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchVendorDetailsList(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_vendor_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteLocationModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_location', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.locationId;
                    if (Object.keys(this.locationManagementTableDetails).length != 0) {
                      var index = this.locationManagementTableDetails["locationLists"].findIndex(loc => loc.AccountLocation.id == id);
                      this.locationManagementTableDetails["locationLists"].splice(index, 1);
                    }                    
                    resolve(response.data);
                  }
        
                })
                .catch(error => console.log(error))
            });
          },
          importLocationModal(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_location_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importLocationCopy(postData) {
            return new Promise((resolve, reject) => {        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_location_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryApprovalDetailList() {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/inventory_approval/' + true)
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = response.data.message;
                  this.inventoryApprovalDetails = new Array();
                  if (parseData != null) {
                    tData['approveDetails'] = parseData;
                    this.inventoryApprovalDetails = tData;
                  }                  
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryApprovalTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/get_all_inventory_approvals', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.inventoryApprovalTableDetails = new Array();
                  if (parseData != null) {
                    tData['approveLists'] = parseData;
                    this.inventoryApprovalTableDetails = tData;
                  }                  
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          fetchInventoryRequestTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/get_all_inventory_requests', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  var tData = {};
                  var parseData = JSON.parse(res.data.message);
                  this.inventoryApprovalTableDetails = new Array();
                  if (parseData != null) {
                    tData['approveLists'] = parseData;
                    this.inventoryApprovalTableDetails = tData;
                  }                  
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          fetchInventoryApprovalPendingTableList(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.post('/inventory/inventory_approval_pending', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  Nprogress.done()
                  var tData = {};
                  this.inventoryApprovePending = new Array();
                  this.inventoryApprovePending = res.data.requestsSummary;
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          inventoryDashboardVisibleSet(data) {
            this.showStockPage = data
          },
          getInventoryDetail(postData){
            Nprogress.start()
            return new Promise((resolve,reject)=>{
              axios.post('/inventory/approve_inventory_request',qs.stringify(postData,{
                parseArrays:false
              }))
            
            .then(response=>{
              Nprogress.done()
              if (response.data.status != 'success') {
                console.log("MESSAGE",response.data.message)
                reject(response.data.message);
                return;
              }
              else{
              resolve(response.data);}
            })
            .catch(error => console.log(error));
          });
          },
          getInventoryItemDetail(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_inventory_item_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          approveItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/approve_inventory_approval', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var successData = {
                      id: postData.invenapprTypeId,
                      accId: postData.accountId,
                    }
                    this.approveInventoryApprovalTableData(successData);
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          rejectItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/reject_inventory_approval', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var successData = {
                      id: postData.invenId,
                      accId: postData.accountId,
                    }
                    this.clearInventoryDashboardDetailsState();
                    this.approveInventoryApprovalTableData(successData);
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryConfigurationDetails() {
            Nprogress.start()
            return new Promise((resolve, reject) => {        
              axios.get('/inventory/inventory_configuration/' + true)
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                    var parseData = response.data.message;
                    this.inventoryConfigurationDetails = new Array();
                    if (parseData != null) {
                        tData['inventoryConfigurationDetails'] = parseData;
                        this.inventoryConfigurationDetails = tData;
                    }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          addAdminConfigureModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/add_configuration', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteAdminConfigureModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_admin_configuration', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.adminId;
                    if (Object.keys(this.inventoryConfigurationDetails).length != 0) {
                        var index = this.inventoryConfigurationDetails["inventoryConfigurationDetails"].configs.findIndex(item => item.Admin.id == id);
                        this.inventoryConfigurationDetails["inventoryConfigurationDetails"].configs.splice(index, 1);
                    }
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          adminViewAllStockModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/admin_view_assignedall_stocks_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          adminViewUnAssingnedStockModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/admin_view_unassigned_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteAdminViewAllStockSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_admin_view_assignedall_stock_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.adminClassificationId;
                    if (Object.keys(this.inventoryConfigurationDetails).length != 0) {
                        var index = this.inventoryConfigurationDetails["inventoryConfigurationDetails"].adminToassignedAllLevels.findIndex(item => item.id == id);
                        this.inventoryConfigurationDetails["inventoryConfigurationDetails"].adminToassignedAllLevels.splice(index, 1);
                    }
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          deleteAdminViewUnAssignedStockSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_admin_view_unassigned_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.adminClassificationId;
                    if (Object.keys(this.inventoryConfigurationDetails).length != 0) {
                        var index = this.inventoryConfigurationDetails["inventoryConfigurationDetails"].adminToUnassignedLevels.findIndex(item => item.id == id);
                        this.inventoryConfigurationDetails["inventoryConfigurationDetails"].adminToUnassignedLevels.splice(index, 1);
                    }
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          saveSubAccountPermissionInventorySettings(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/settings/save_sub_accounts_cannot_change_settings', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (postData.type == 'inventory') {
                    if (postData.switchCheckBoxValue == 'on') {
                      Vue.set(this.inventoryConfigurationDetails["inventoryConfigurationDetails"], 'subAccountsCanNotChangeInventoryConfiguration', 'Yes');
                    } else {
                      Vue.set(this.inventoryConfigurationDetails["inventoryConfigurationDetails"], 'subAccountsCanNotChangeInventoryConfiguration', 'No');
                    }
                  }
                  resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
          },
          addInventoryToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/add_assign_inventory_to_subAcc', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.genericBody);
                })
                .catch(error => console.log(error));
            });
          },
          inventoryConfigurationSubmit(postData) {
            return new Promise((resolve, reject) => {
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/inventory_configuration_submit', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();;
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data.message);
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryStockOverviewList() {
            Nprogress.start()
            return new Promise((resolve, reject) => {
        
              axios.get('/inventory/stock/' + true)
                .then(response => {
                  Nprogress.done()
                  var tData = {};
                    var parseData = response.data.message;
                    this.stockItemSampleFilePath = parseData.samplePath;
                    this.inventoryStockOverviewDetails = new Array();
                    if (parseData != null) {
                        tData['inventoryStockDetails'] = parseData;
                        this.inventoryStockOverviewDetails = tData;
                    }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchInventoryStockOverviewTableList(postData) {
            if (postData.load == true) {
              Nprogress.start()
            }
            return new Promise((resolve, reject) => {
              axios.post('/inventory/item_details_data', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(res => {
                  if (postData.load == true) {
                    Nprogress.done()
                  }
                  if (res.data.status == "redirect") {
                    router.push(res.data.message);
                  } else {
                    var tData = {};
                    var parseData = JSON.parse(res.data.message);
                    this.inventoryStockOverviewTableDetails = new Array();
                    if (parseData != null) {
                        tData['inventoryStockList'] = parseData;
                        this.inventoryStockOverviewTableDetails = tData;
                    }
                  }
                  resolve(res.data);
                })
                .catch(error => console.log(error))
            });
          },
          fetchInventoryItemDetail(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/get_inventory_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteStockItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_item_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    var id = postData.itemDetailsId;
                    if (Object.keys(this.inventoryStockOverviewTableDetails).length != 0) {
                        var index = this.inventoryStockOverviewTableDetails["inventoryStockList"].findIndex(item => item.InventoryItemDetail.id == id);
                        this.inventoryStockOverviewTableDetails["inventoryStockList"].splice(index, 1);
                    }
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          deleteMultiStockItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/delete_multiitem_details', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          createStockItemModalSubmit(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/stock', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.status == 'success') {
                    this.clearInventoryDashboardDetailsState();
                    resolve(response.data);
                  }
                })
                .catch(error => console.log(error));
            });
          },
          importStockItemModal(postData) {
            return new Promise((resolve, reject) => {        
             JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_stock_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importStockItemCopy(postData) {
            return new Promise((resolve, reject) => {        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/inventory/import_inventory_stock_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          approveInventoryApprovalTableData(data) {
            var id = data.id;
            var accId = data.accId;
            if (Object.keys(this.inventoryApprovalTableDetails).length != 0) {
                var index = this.inventoryApprovalTableDetails["approveLists"].findIndex(item => item.InventoryLeadApproval.id == id && item.InventoryLeadApproval.account_id == accId);
                this.inventoryApprovalTableDetails["approveLists"].splice(index, 1);
            }
          },
          addItem(data) {
            this.add_item = data;
          },
          addVendor(data) {
            this.add_vendor = data;
          }
    }    
})